const agent = [
    {
        path: '/agent/examine',
        name: 'examine',
        component: () => import('@/view/agent/examine'),
    },
    {
        path: '/agent/manage',
        name: 'manage',
        component: () => import('@/view/agent/manage')
    },
    {
        path: '/agent/manage/info',
        name: 'manageInfo',
        component: () => import('@/view/agent/manage/info')
    }
]
export default agent;