const build = [
    {
        path: '/build/city',
        name: 'city',
        component: () => import('@/view/build/city')
    },
    {
        path: '/build/city/region',
        name: 'region',
        component: () => import('@/view/build/city/region')
    },
    {
        path: '/build/city/metro',
        name: 'metro',
        component: () => import('@/view/build/city/metro')
    },
    {
        path: '/build/manage',
        name: 'manage',
        component: () => import('@/view/build/manage')
    },
    {
        path: '/build/manage/info',
        name: 'info',
        component: () => import('@/view/build/manage/info')
    }
]
export default build