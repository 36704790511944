const honey = [
    {
        path: '/honey/setting',
        name: 'setting',
        component: () => import('@/view/honey/setting'),
    },
    {
        path: '/honey/user',
        name: 'user',
        component: () => import('@/view/honey/user'),
    },
    {
        path: '/honey/withdraw',
        name: 'withdraw',
        component: () => import('@/view/honey/withdraw'),
    },
    {
        path: '/honey/data',
        name: 'data',
        component: () => import('@/view/honey/data'),
    }
]
export default honey