const house = [
    {
        path: '/house/label',
        name: 'label',
        component: () => import('@/view/house/label'),
    },
    {
        path: '/house/second',
        name: 'second',
        component: () => import('@/view/house/second'),
    },
    {
        path: '/house/second/create',
        name: 'secondCreate',
        component: () => import('@/view/house/second/create'),
    },
    // 设置户型
    {
        path: '/house/second/houseType',
        name: 'secondHouseType',
        component: () => import('@/view/house/second/houseType'),
    },
    {
        path: '/house/second/signAContract',
        name: 'signAContract',
        component: () => import('@/view/house/second/signAContract')
    },
    {
        path: '/house/tenancy',
        name: 'tenancy',
        component: () => import('@/view/house/tenancy'),
    },
        // 设置户型
        {
            path: '/house/tenancy/houseType',
            name: 'tenancyHouseType',
            component: () => import('@/view/house/tenancy/houseType'),
        },
    {
        path: '/house/tenancy/create',
        name: 'tenancyCreate',
        component: () => import('@/view/house/tenancy/create'),
    },
    {
        path: '/house/tenancy/signAContract',
        name: 'signAContract',
        component: () => import('@/view/house/tenancy/signAContract')
    },
    {
        path: '/house/new',
        name: 'new',
        component: () => import('@/view/house/new'),
    },
    {
        path: '/house/new/create',
        name: 'newCreate',
        component: () => import('@/view/house/new/create'),
    },
    {
        path: '/house/secondExamine',
        name: 'secondExamine',
        component: () => import('@/view/house/secondExamine'),
    },
    {
        path: '/house/secondExamine/detail',
        name: 'secondExamineDetail',
        component: () => import('@/view/house/secondExamine/detail'),
    },
    {
        path: '/house/tenancyExamine',
        name: 'tenancyExamine',
        component: () => import('@/view/house/tenancyExamine'),
    },
    {
        path: '/house/tenancyExamine/detail',
        name: 'tenancyExamineDetail',
        component: () => import('@/view/house/tenancyExamine/detail'),
    },
    {
        path: '/house/view',
        name: 'view',
        component: () => import('@/view/house/view'),
    },
    {
        path: '/house/view/detail',
        name: 'viewDetail',
        component: () => import('@/view/house/view/detail')
    },
    {
        path: '/house/view/record',
        name: 'viewRecord',
        component: () => import('@/view/house/view/record')
    },
    {
        path: '/house/help',
        name: 'help',
        component: () => import('@/view/house/help'),
    },
    {
        path: '/house/look',
        name: 'look',
        component: () => import('@/view/house/look'),
    },
    {
        path: '/house/look/detail',
        name: 'lookDetail',
        component: () => import('@/view/house/look/detail'),
    }
]
export default house