import {get, post, put,Delete} from '../axios'
/* 用户管理-列表 */
export const userlist = params => get('/web/system/user/list',params);

// 用户管理-查看
export const userView = params => get(`/web/system/user/view/${params}`,'');

// 用户管理-修改
export const userUpdate = params => put('/web/system/user/update',params);

// 用户管理-新增
export const userAdd = params => post('/web/system/user/add',params);

// 用户管理-删除
export const userDelete = params => Delete(`/web/system/user/delete/${params}`,'');

// 用户管理-角色列表
export const userRole = params => get('/web/system/user/role',params);

// 用户管理-用户角色查看
export const roleView = params => get(`/web/system/user/role/view/${params}`,'');

// 用户管理-用户角色修改
export const roleUpdate = params => put('/web/system/user/role/update',params);

// 用户管理-密码重置
export const passwordReset = params => get(`/web/system/user/password/reset/${params}`,'');

// 用户管理-新增-获取省市树形
// /web/system/user/region/list
export const regionList = params => get('/web/system/user/region/list',params)

// 用户管理-城市选择
// /web/system/user/city/list
export const cityList = params => get('/web/system/user/city/list',params)