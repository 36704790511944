const contract = [
    {
        path: '/contract/template',
        name: 'template',
        component: () => import('@/view/contract/template')
    },
    {
        path: '/contract/customer',
        name: 'customer',
        component: () => import('@/view/contract/customer')
    },
    {
        path: '/contract/customer/detail',
        name: 'detail',
        component: () => import('@/view/contract/customer/detail')
    },
    {
        path: '/contract/customer/nodeSecondInfo',
        name: 'nodeSecondInfo',
        component: () => import('@/view/contract/customer/nodeSecondInfo')
    },
    {
        path: '/contract/customer/nodeRentInfo',
        name: 'nodeRentInfo',
        component: () => import('@/view/contract/customer/nodeRentInfo')
    },
    {
        path: '/contract/customer/journal',
        name: 'journal',
        component: () => import('@/view/contract/customer/journal')
    }
]
export default contract