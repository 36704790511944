const base = [
    {
        path: '/base/banner',
        name: 'banner',
        component: () => import('@/view/base/banner'),
    },
    {
        path: '/base/bannerpc',
        name: 'bannerpc',
        component: () => import('@/view/base/bannerpc'),
    },
    {
        path: '/base/vajra',
        name: 'vajra',
        component: () => import('@/view/base/vajra'),
    },
    {
        path: '/base/app',
        name: 'app',
        component: () => import("@/view/base/app")
    },
    {
        path: '/base/pc',
        name: 'pc',
        component: () => import("@/view/base/pc")
    }
]
export default base;