import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import store from './store';
Vue.use(Vuex);

import router from './router'
import VueRouter from 'vue-router'
Vue.use(VueRouter);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// Avue引用
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
Vue.use(Avue);

import AvueUeditor from 'avue-plugin-ueditor'
Vue.use(AvueUeditor);

Vue.config.productionTip = false

import {actionUrl, videoUrl} from '@/config/public';
Vue.prototype.actionUrl = actionUrl;
Vue.prototype.videoUrl = videoUrl;

import global from '@/utils/global';
Vue.prototype.global = global;

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


import pagesHeader from '@/components/a-pages/pagesHeader.vue';
Vue.component("pagesHeader", pagesHeader);

new Vue({
  router,
  store,
  data: {
    eventHub: new Vue()  //vue 全局事件(eventBus)设置
  },
  render: h => h(App),
}).$mount('#app')
