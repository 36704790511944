import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import pages from './pages'
import indexHome from './view'
import base from './view/base'
import build from './view/build'
import shop from './view/shop'
import honey from './view/honey'
import house from './view/house'
import newHouse from "./view/new";
import user from "./view/user"
import agent from "./view/agent"
import supplier from "./view/supplier"
import finance from "./view/finance"
import data from "./view/data"
import system from './view/system'
import contract from './view/contract'

// 首页
import index from '@/pages/index/index';

// view 内容

export default new Router({
	// mode: 'history',//去掉#号、
	mode: 'hash', //保留#号、
	routes: [
		...pages,
		{
			path: '/',
			component: index,
			children: [
				...indexHome,
				...contract,
				...base,
				...build,
				...house,
				...newHouse,
				...user,
				...agent,
				...supplier,
				...honey,
				...shop,
				...finance,
				...data,
				// ------------------------------------------------------ 系统管理
				...system
			]
		}
	]
})
