const shop = [
    {
        path: '/shop/classify',
        name: 'classify',
        component: () => import('@/view/shop/classify'),
    },
    {
        path: '/shop/spec',
        name: 'spec',
        component: () => import('@/view/shop/spec'),
    },
    {
        path: '/shop/spec/specSon',
        name: 'specSon',
        component: () => import('@/view/shop/spec/specSon')
    },
    {
        path: '/shop/goods',
        name: 'goods',
        component: () => import('@/view/shop/goods'),
    },
    {
        path: '/shop/goods/create',
        name: 'goodsCreate',
        component: () => import('@/view/shop/goods/create'),
    },
    {
        path: '/shop/order',
        name: 'order',
        component: () => import('@/view/shop/order'),
    },
    {
        path: '/shop/order/info',
        name: 'orderInfo',
        component: () => import('@/view/shop/order/info'),
    }
]
export default shop